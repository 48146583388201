<template>
  <div class="app-container">
    <div class="head-container">
      <!-- <quick-select v-model="query.sellerId" filterable url="api/purchase/seller" placeholder="供应商" value-field="belongTo" display-field="belongToName" clearable @change="toQuery" class="filter-item" style="width: 180px;" /> -->
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="盘点开始日期" end-placeholder="盘点结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-input v-model="query.warehouseName" clearable placeholder="库房名称" class="filter-item" style="width: 150px" @keyup.enter.native="toQuery" />
      <el-input v-model="query.warehouseErpCode" clearable placeholder="库房编码" class="filter-item" style="width: 150px" @keyup.enter.native="toQuery" />      
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="add">新建盘点单</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" style="width: 100%;">
      <el-table-column prop="formCode" label="盘点单号" width="150" />
      <el-table-column prop="warehouseName" label="库房名称" show-overflow-tooltip />
      <el-table-column prop="warehouseErpCode" label="库房编码" />
      <el-table-column prop="managerName" label="盘点负责人姓名" show-overflow-tooltip />
      <!-- <el-table-column prop="editBy" label="修改人"/>
      <el-table-column prop="checkerName" label="审批人姓名"/>
      <el-table-column prop="checkInfo" label="审批意见"/>-->
      <el-table-column prop="stocktakingTime" label="盘点时间" :formatter="r=>{return new Date(r.stocktakingTime).format()}" />
      <el-table-column prop="info" label="备注" min-width="160" show-overflow-tooltip />
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type" :key="scope.row.id">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column width="100px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <template v-if="scope.row.status === 0">
            <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
            <el-popover :ref="scope.row.id" placement="top" width="180" v-if="scope.row.status === 0">
              <p>确定删除该盘点单吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </template>
          <template v-else-if="scope.row.status === 1">
            <!-- 已复核 才能打印 -->
            <el-button size="mini" type="text" @click="print(scope.row)">打印</el-button>
          </template>
          <el-button size="mini" type="text" @click="view(scope.row)">查看</el-button>
        </div>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" />
    <inbound-form ref="inboundForm" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";
import inboundForm from "../../warehouse/inbound/form";
import { del } from "@/api/inventory";

export default {
  components: { eForm, inboundForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      status: [
        {
          type: "warning",
          label: "未复核",
        },
        {
          type: "success",
          label: "已复核",
        },
      ],
      query: {
        dateRange: null,
        formCode: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/stocktaking";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: "stocktakingTime,desc" },
        query
      );
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    add() {
      this.$refs.form && this.$refs.form.resetForm();
      this.$refs.form.disabled = false;
    },
    edit(data) {
      this.$refs.form && this.$refs.form.resetForm(data.id);
      this.$refs.form.disabled = false;
    },
    view(data) {
      this.$refs.form && this.$refs.form.resetForm(data.id);
      this.$refs.form.disabled = true;
    },
    print(data) {
      this.$print("inventory", data.id);
    },
    // inbound(data) {
    //   this.$refs.inboundForm && this.$refs.inboundForm.generateByPurchaseOrder(data.id);
    // },
  },
};
</script>